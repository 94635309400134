var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"email-compose-card"},[_c('div',{staticClass:"compose-header px-3 py-2 d-flex align-center"},[_c('span',{staticClass:"text--primary font-weight-medium text-base"},[_vm._v("Compose Mail")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('close-dialog');}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiMinus)+" ")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('update:isDialogFullscreen', !_vm.isDialogFullscreen)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.isDialogFullscreen? _vm.icons.mdiFullscreenExit : _vm.icons.mdiFullscreen)+" ")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.discardEmail}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c('v-form',[_c('v-combobox',{key:_vm.shallShowCCFIeld.toString() + _vm.shallShowBCCFIeld.toString(),staticClass:"pt-0",attrs:{"items":_vm.emailToOptions,"filter":_vm.filterEmailToOptions,"multiple":"","chips":"","small-chips":"","deletable-chips":"","hide-selected":"","hide-details":"","item-text":"name","item-value":"email","solo":"","flat":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.item;
return [_c('v-avatar',{staticClass:"me-2",attrs:{"size":"22"}},[_c('v-img',{attrs:{"src":option.avatar}})],1),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(option.name))])]}},{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"text--primary"},[_vm._v("To:")])]},proxy:true},{key:"append",fn:function(){return [_c('span',[_vm._v(" ")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.shallShowCCFIeld = !_vm.shallShowCCFIeld}}},[_vm._v(" Cc ")]),_c('v-btn',{staticClass:"me-6 ms-1",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.shallShowBCCFIeld = !_vm.shallShowBCCFIeld}}},[_vm._v(" Bcc ")])]},proxy:true}]),model:{value:(_vm.composeData.to),callback:function ($$v) {_vm.$set(_vm.composeData, "to", $$v)},expression:"composeData.to"}}),_c('v-divider'),_c('v-combobox',{directives:[{name:"show",rawName:"v-show",value:(_vm.shallShowCCFIeld),expression:"shallShowCCFIeld"}],staticClass:"pt-0",attrs:{"items":_vm.emailToOptions,"filter":_vm.filterEmailToOptions,"multiple":"","solo":"","flat":"","chips":"","small-chips":"","deletable-chips":"","hide-selected":"","auto-select-first":"","hide-details":"","item-text":"name","item-value":"email"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.item;
return [_c('v-avatar',{staticClass:"me-2",attrs:{"size":"22"}},[_c('v-img',{attrs:{"src":option.avatar}})],1),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(option.name))])]}},{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"text--primary"},[_vm._v("Cc:")])]},proxy:true},{key:"append",fn:function(){return [_c('span',[_vm._v(" ")])]},proxy:true}]),model:{value:(_vm.composeData.cc),callback:function ($$v) {_vm.$set(_vm.composeData, "cc", $$v)},expression:"composeData.cc"}}),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.shallShowCCFIeld),expression:"shallShowCCFIeld"}]}),_c('v-combobox',{directives:[{name:"show",rawName:"v-show",value:(_vm.shallShowBCCFIeld),expression:"shallShowBCCFIeld"}],staticClass:"pt-0",attrs:{"items":_vm.emailToOptions,"filter":_vm.filterEmailToOptions,"multiple":"","solo":"","flat":"","chips":"","small-chips":"","deletable-chips":"","hide-selected":"","auto-select-first":"","hide-details":"","item-text":"name","item-value":"email"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.item;
return [_c('v-avatar',{staticClass:"me-2",attrs:{"size":"22"}},[_c('v-img',{attrs:{"src":option.avatar}})],1),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(option.name))])]}},{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"text--primary"},[_vm._v("Bcc:")])]},proxy:true},{key:"append",fn:function(){return [_c('span',[_vm._v(" ")])]},proxy:true}]),model:{value:(_vm.composeData.bcc),callback:function ($$v) {_vm.$set(_vm.composeData, "bcc", $$v)},expression:"composeData.bcc"}}),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(_vm.shallShowBCCFIeld),expression:"shallShowBCCFIeld"}]}),_c('v-text-field',{staticClass:"mb-1 pt-0",attrs:{"hide-details":"","solo":"","flat":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"text--primary"},[_vm._v("Subject:")])]},proxy:true}]),model:{value:(_vm.composeData.subject),callback:function ($$v) {_vm.$set(_vm.composeData, "subject", $$v)},expression:"composeData.subject"}}),_c('v-divider'),_c('v-textarea',{staticClass:"email-compose-message",attrs:{"solo":"","flat":"","label":"Message","hide-details":"","auto-grow":""},model:{value:(_vm.composeData.message),callback:function ($$v) {_vm.$set(_vm.composeData, "message", $$v)},expression:"composeData.message"}}),_c('v-divider'),_c('div',{staticClass:"pa-4 d-flex align-center"},[_c('v-menu',{attrs:{"offset-y":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn-toggle',{staticClass:"send-btn-toggle me-3",attrs:{"color":"primary","borderless":""}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.sendMail}},[_c('span',[_vm._v("Send")])]),_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronDown)+" ")])],1)],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Schedule Send")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Save as Draft")])],1)],1)],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAttachment)+" ")])],1),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","top":"","min-width":"140"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Print")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Check Spelling")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',[_vm._v("Plain Text Mode")])],1)],1)],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.discardEmail}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }